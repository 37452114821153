.widget-logo {
    --padding-h: 0;
    --padding-top: 0; //var(--padding-s);
    --padding-bottom: var(--padding-s);
}

.widget-logo {
    margin: var(--padding-top) 0 var(--padding-bottom);
    position: relative;
    @include z-index(1);

    a {
        @include basic-a();
        display: block;
    }

    .icon {
        width: 100%;
    }

    svg {
        fill: var(--primary-color);
    }
}
