#Preloader {
    --color: var(--black);
    --bg: var(--white);
}

#Preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    @include z-index($z-index-loader);
    color: var(--color);
    background-color: var(--bg);
    
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;

    .icon {
        width: 20vw;
    }
    
    @media (max-width: $smartphone) {
        .icon {
            width: 40vw;
        }
    }
}
