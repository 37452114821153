@use "sass:math";

:root {
    --font-sans: 'PPEditorialNew-Ultralight', sans-serif;
    --font-sans-italic: 'PPEditorialNew-UltralightItalic', sans-serif;

    --font-size-large: #{math.div(32px, 16px) * 1rem};
    --font-size-medium: #{math.div(18px, 16px) * 1rem};
    --font-size-base: #{math.div(14px, 16px) * 1rem};
    --font-size-small: #{math.div(10px, 16px) * 1rem};

    --zoom: 1;
    --line-height-sans: 1.2;
    --letter-spacing: 0;

    --min-font-size: 10px;
    --max-font-size: 48px;
    --font-size: clamp(var(--min-font-size), 1.1111vw * var(--zoom), var(--max-font-size));
    font-size: var(--font-size);
    
    @media (max-width: $smartphone) {
        --font-size: 16px;
        --font-size-small: #{math.div(10px, 16px) * 1rem};
    }
}

@mixin font-sans($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-italic($line-height: 0) {
    font-family: var(--font-sans-italic);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-base($line-height: 0) {
    @include font-sans();
    font-size: var(--font-size-base);
}

@mixin font-base-italic($line-height: 0) {
    @include font-sans-italic();
    font-size: var(--font-size-base);
}

@mixin font-large($line-height: 0) {
    @include font-sans();
    font-size: var(--font-size-large);
    font-weight: normal;
}

@mixin font-medium($line-height: 0) {
    @include font-sans();
    font-size: var(--font-size-medium);
    font-weight: normal;
}

@mixin font-medium-italic($line-height: 0) {
    @include font-sans-italic();
    font-size: var(--font-size-base);
}

@mixin font-small($line-height: 0) {
    @include font-sans();
    font-size: var(--font-size-small);
}
