#Footer {
    --padding-h: var(--padding-s);
    --padding-top: var(--padding-s);
    --padding-bottom: var(--padding-xl);
    
    @media (max-width: $smartphone) {
        --padding-bottom: var(--padding-l);
        --padding-h: var(--padding-xxs);
    }

    &.widget-footer {
        --padding-bottom: var(--padding-s);
        
        @media (max-width: $smartphone) {
            --padding-bottom: var(--padding-s);
        }
    }
}

#Footer {
    @include font-small();
    text-transform: uppercase;

    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);

    a {
        @include basic-a();
        @include font-small();
        color: currentColor;
    }

    @include isCursor() {
        a:hover {
            text-decoration: underline;
        }
    }

    @media (max-width: $smartphone) {
        > *:last-child {
            display: flex;
            justify-content: flex-end;
        }

        > div:first-child,
        > div:nth-child(2),
        > div:nth-child(3) {
            display: flex;
            justify-content: space-between;
            margin-bottom: var(--padding-xs);
        }

        a {
            margin-left: var(--padding-xxs);
        }
    }
    
    @media (min-width: $smartphone) {
        column-count: 3;
        
        a {
            margin-right: var(--padding-xs);
        }

        > div {
            > div:last-child:not(:first-child) {
                margin-top: var(--padding-xs);
            }
        }

        .author {
            position: absolute;
            right: var(--padding-h);
            bottom: var(--padding-bottom);
        }
    }
}
