.block-text {
    --max-width: #{rem(900)};
    --padding-h: var(--padding-s);
    --padding-top: var(--padding-m);
    --padding-bottom: var(--padding-xl);
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xxs);
        --padding-bottom: var(--padding-s);
    }
}

.block-text {
    @include font-medium();
    
    > div {
        max-width: var(--max-width);
        padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
        margin: 0 auto;
    }

    h1 {
        @include font-large();
        margin: .5em 0;
    }

    h2 {
        @include font-large();
        margin: .5em 0;
    }

    h3 {
        @include font-large();
        margin: .5em 0;
    }

    p {
        i {
            @include font-medium();
        }

        strong,
        b {
            @include font-medium-italic();
        }
        margin: .5em 0;
    }

    ul, ol {
        margin: 2em 0;
        padding-left: 4em;
    }

    a {
        @include basic-a();
        @include font-medium-italic();
        text-decoration: underline;
        color: inherit;
    }
}
