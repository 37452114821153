#music-player {
    --icon: #{rem(20)};
    --padding-h: var(--padding-s);
    --padding-v: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xxs);
        --padding-v: var(--padding-xs);
    }

    @include font-base();
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--padding-s);
    background-color: var(--white);

    position: fixed;
    bottom: var(--padding-s);
    left: var(--padding-h);
    right: var(--padding-h);

    border: 1px solid var(--primary-color);
    padding: var(--padding-v) var(--padding-h);

    opacity: 0;
    pointer-events: none;

    &.--play {
        [data-play] { display: none; }

        .basic-marquee {
            --animation: 6s;
        }

        .bar {
            pointer-events: initial;
        }
    }
    
    &.--stop {
        [data-pause] { display: none; }

        [data-link] {
            opacity: .4;
            pointer-events: none;
        }
    }
    
    &.--pause {
        [data-pause] { display: none; }
        
        .now-playing {
            opacity: .4;
        }

        .basic-marquee {
            --animation: 0s;
        }
    }

    button {
        background-color: transparent;
        padding: 0;
    }

    svg {
        display: block;
        height: var(--icon);
        width: var(--icon);
        fill: var(--primary-color);
    }

    .now-playing {
        @include font-base-italic();
    }

    .controls {
        display: flex;
        gap: var(--padding-xs);
    }

    .name {
        overflow: hidden;
    }

    .bar {
        margin-bottom: var(--padding-xxxs);
        padding: var(--padding-xxs) 0;
        cursor: pointer;
        flex-grow: 1;
        pointer-events: none;
        
        > * {
            pointer-events: none;
        }

        .track {
            height: 1px;
            width: 100%;
            position: relative;
            display: block;
            background: var(--primary-color);
        }

        .thumb {
            position: absolute;
            top: 0;
            left: -100%;
            width: 100%;
            height: 200%;
            pointer-events: none;
        }

        .icon {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate3d(50%, -50%, 0);
            display: block;
            
            svg {
                display: block;
                height: rem(14);
                width: rem(14);
            }
        }
    }

    .time {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 rem(45);
        text-align: center;
    }

    @media (max-width: $smartphone) {
        line-height: 1;
        flex-wrap: wrap;  
        gap: var(--padding-xs);

        .now-playing, 
        .name {
            display: none;
        }

        .track {
            width: 100%;
        }
    }

    @media (min-width: $smartphone) {
        .name {
            flex: 0 0 rem(230);
        }
    }
}
