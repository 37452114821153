.track-player {
    text-align: center;
    
    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    } 

    p {
        @include font-base();
    }

    a {
        @include basic-a();
        text-decoration: underline;
        color: currentColor;
    }

    @media (max-width: $smartphone) {
        margin-top: 20vh;
    }

    @media (min-width: $smartphone) {
        height: calc(100vh - var(--header-height));
        display: flex;
        align-items: center;
        justify-content: center;
    }
}