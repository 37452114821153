.widget-tracks {
    --padding-h: var(--padding-s);
    --padding-top: var(--padding-s);
    --padding-bottom: var(--padding-xl);
    
    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xxs);
        --padding-bottom: var(--padding-s);
    }
}

.widget-tracks {
    @include font-base();
    
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
    
    .left-col {
        background-color: var(--primary-color);
        color: var(--primary-bg);
        text-transform: uppercase;
        padding: var(--padding-xxxs);
        
        > span {
            display: block;
            margin-top: .2em;
            line-height: 1;
        }
    }

    .track {
        background-color: transparent;
        text-align: left;
        padding: var(--padding-xxxs);
        word-break: break-all;

        > span {
            display: inline-block;
            margin-top: .2em;

            &::before {
                content: '→';
                display: none;
                margin-right: em(4);
            }
        }

        &[disabled] {
            pointer-events: none;
            opacity: .4;
        }

        &.--active {
            background-color: rgba(0, 0, 0, .2);
            pointer-events: none;

            > span::before {
                display: initial;
            }
        }

        @include isCursor() {
            &:hover {
                background-color: var(--primary-color);
                color: var(--primary-bg);

                > span::before {
                    display: initial;
                }
            }
        }
    }

    .list {
        position: relative;
    }

    .filters {
        margin-bottom: var(--padding-top);
        display: flex;
        flex-wrap: wrap;
        gap: var(--padding-xxxs);
        position: relative;
    }

    @media (max-width: $smartphone) {
        .list {
            column-count: 2;
        }

        .track {
            width: 100%;
        }

        .row {
            &:not(:first-child) {
                .left-col {
                    margin-top: var(--padding-xs);
                }
            }
        }
    }
    
    @media (min-width: $smartphone) {
        .list {
            column-count: 3;
        }

        .row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        .track {
            > span {
                line-height: 1;
            }
        }
    }
}

#ImagesContainer {
    @include z-index(0);

    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;

    display: flex;
    align-items: center;
    justify-content: center;
    
    // pointer-events: none;
    
    img {
        position: absolute;
        width: 10vw;  
        height: auto;  
        opacity: 0;

        &.--active {
            opacity: 1;
        }
    }
}